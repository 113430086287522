<template>
    <v-app>

        <PreorderNavbar :isSimia="isSimiaroom"></PreorderNavbar>

        <v-content>
            <v-dialog v-model="loading" max-width="500" persistent>
                <v-card>
                    <v-card-text class="pt-5">
                        <v-progress-linear
                                active
                                indeterminate
                                query
                                rounded
                                stream
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>

                <Login v-if="!loggedIn"></Login>
            <v-container v-else>

                <ExpressComponent></ExpressComponent>
            </v-container>

        </v-content>
    </v-app>
</template>

<script>

    /*eslint-disable*/
    import PreorderNavbar from '@/components/PreorderNavbar.vue';
    import ExpressComponent from '@/components/clients/ExpressComponent.vue';
    import {EventBus} from "@/event-bus";
    import {mapGetters} from "vuex";
    import Login from '@/views/Login';

    export default {
        components: {

            PreorderNavbar,
            ExpressComponent,
            Login
        },
        computed: {
            ...mapGetters({
                'loggedIn': 'userIsLoggedIn',
                'platform': 'getPlatform'
            })
        },
        data() {
            return {
                loading: false,
                aboutUsTitle: 'درباره سیمیاروم',
                aboutUsText: 'اگر از مشاوره روانشناسی آنلاین اطلاعات کمی دارید توصیه می کنیم راهنمای مشاوره های آنلاین روانشناسی را در لینک زیر مشاهده نمایید:',
                isSimiaroom: false,
                hasCouponCode: false,
                couponCode: null,
                showOfflinePaymentDialog: false,
                validating: false,
                checkingEmail: false,
                sendingOrder: false,
                snackbar: {
                    visibility: false,
                    color: 'info',
                    position: 'bottom',
                    timeout: 5000,
                    mode: 'vertical',
                    message: 'this is snackbar message',
                },
                emailRules: [
                    v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
                    v => /.+@.+/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
                ],
                action: null,
                state: null,
                accounts: [
                    {
                        icon: 'fab fa-facebook',
                        link: 'https://www.facebook.com/simiatalk'

                    },
                    {
                        icon: 'fab fa-twitter',
                        link: 'https://twitter.com/SimiaRoom'

                    },

                    {
                        icon: 'fab fa-linkedin',
                        link: 'https://www.linkedin.com/company/simiaroom/'

                    },
                    {
                        icon: 'fab fa-instagram',
                        link: 'https://www.instagram.com/simiaroomcom/'

                    },
                    {
                        icon: 'fab fa-youtube',
                        link: 'https://www.youtube.com/channel/UCgM4KoG4MZg-ATBchGzQnAw/videos'
                    },
                ],
                window: 1,
                tab: 1,
                email: ''
            }
        },

        mounted() {
            EventBus.$on('notify', this.showSnackbar);
            // this.validatePackage();
        },
        created() {


        },
        beforeDestroy() {
            EventBus.$off('notify', this.showSnackbar);
        },
        methods: {
            showSnackbar(color = 'success', timeout = 5000, message = 'عملیات با موفقیت انجام شد.') {
                this.snackbar.visibility = true;
                this.snackbar.color = color;
                this.snackbar.position = 'bottom';
                this.snackbar.timeout = timeout;
                this.snackbar.mode = 'vertical';
                this.snackbar.message = message;

            },
            checkUserStatus() {
                if (this.$refs.registerForm.validate()) {
                    this.checkingEmail = true;
                    this.$store.dispatch('checkUserAvailability', {email: this.email}).then((resp) => {
                        this.action = resp.data.action;
                        this.tab = this.action;
                    }).finally(() => {
                        this.checkingEmail = false;
                    });
                }
            },
            validatePackage() {
                this.loading = true;
                let item = {
                    id: this.$route.params.orderId,
                    preorder: true
                };
                this.validating = true;

                this.$store.dispatch('addToCart', item).then(() => {
                    this.validating = false;
                    if (this.hasCouponCode) {
                        this.couponCode = this.$route.query.coupon_code;
                    }
                }).catch(() => {
                    this.validating = false;
                    setTimeout(() => {
                        this.$router.push({name: 'login', params: {lang: 'fa'}});
                    }, 3000)

                }).finally(() => {
                    this.validating = false;
                    this.loading = false;
                });

            },
            // sendOrder() {
            //     this.sendingOrder = true;
            //     EventBus.$emit('sendOrderEvent');
            // },
            userLoggedIn(newUser) {

                let user = {
                    email: newUser.email,
                    password: newUser.password
                };


                this.$store.dispatch('getPlatformInfo');
                this.$store.dispatch("login", user).then(() => {
                    this.$store.dispatch('getCart');
                    this.$store.dispatch('getUserInfo').then(() => {
                        this.$store.commit('setLoggedIn');
                        EventBus.$emit('userLoggedIn');
                    })
                })

            },
        }
    }
</script>

<style scoped>


    .preorder-footer-link {
        color: white !important;
        text-decoration: none;
    }

    .custom-gradient {
        background-image: repeating-linear-gradient(45deg, black, transparent 100px);
    }

    .light-green-button {
        color: #a2fa30;
        font-size: 1.2em;
        opacity: 1;
        text-decoration: none;
    }
</style>