<template>

    <v-container fluid>
        <v-snackbar

                v-model="snackbar.visibility"
                :color="snackbar.color"
                :bottom="snackbar.position === 'bottom'"
                :timeout="snackbar.timeout"
                :top="snackbar.position === 'top'"
        >
            {{snackbar.message}}
            <v-btn
                    color="primary--text"
                    @click="snackbar.visibility = false"
                    fab
                    small
            >
                <v-icon x-small color="red">fas fa-times</v-icon>
            </v-btn>
        </v-snackbar>

        <v-dialog v-if="loaded" max-width="1100" v-model="wizard" persistent scrollable>
            <v-card class="elevation-0">
                <v-card-text class="pa-0 ma-0">
                    <v-stepper v-model="step">
                        <v-stepper-header class="warning">
                            <!--<v-stepper-step :editable="step > 1" :complete="step > 1" step="1">اطلاعات پایه-->
                            <!--</v-stepper-step>-->
                            <v-divider></v-divider>
                            <v-stepper-step :editable="step > 1 || !this.profile.state.requireSign" :complete="step > 1"
                                            step="1">خرید بسته
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" :editable="profile.wallet.total!=0"
                                            step="2">{{sessionRequestTitle}}
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 3" step="3">انتخاب مشاور</v-stepper-step>
                            <v-divider></v-divider>
                            <!--<v-stepper-step :complete="step > 4" step="4">انتخاب زمان مشاور</v-stepper-step>-->
                            <!--<v-divider></v-divider>-->
                            <v-stepper-step :complete="step > 4" step="4">ثبت زمان پیشنهادی
                            </v-stepper-step>

                            <!--<v-stepper-step :editable="step > 5" step="5">درخواست جلسه</v-stepper-step>-->
                            <!--<v-divider></v-divider>-->

                        </v-stepper-header>

                        <v-stepper-items>
                            <!--<v-stepper-content step="1">-->
                            <!--<v-card class="elevation-0"-->
                            <!--&gt;-->
                            <!--<v-card-text>-->
                            <!--<v-form ref="profileForm">-->
                            <!--<v-row>-->


                            <!--<v-col cols="12" sm="6" md="4">-->
                            <!--<v-text-field v-model="profileInfo.name" outlined-->
                            <!--label="نام"-->
                            <!--:rules="requiredRules"-->
                            <!--&gt;</v-text-field>-->
                            <!--</v-col>-->
                            <!--<v-col cols="12" sm="6" md="4">-->
                            <!--<v-text-field v-model="profileInfo.family" outlined-->
                            <!--:rules="requiredRules"-->
                            <!--label="نام خانوادگی"></v-text-field>-->
                            <!--</v-col>-->
                            <!--<v-col cols="12" sm="6" md="4">-->
                            <!--<v-autocomplete-->
                            <!--v-model="profileInfo.country"-->
                            <!--:items="countries"-->
                            <!--item-text="name"-->
                            <!--item-value="id"-->
                            <!--menu-props="auto"-->
                            <!--label="کشور"-->
                            <!--hide-details-->
                            <!--prepend-icon="fas fa-flag"-->
                            <!--single-line-->
                            <!--outlined-->
                            <!--:rules="requiredRules"-->
                            <!--&gt;</v-autocomplete>-->
                            <!--</v-col>-->
                            <!--<v-col cols="12" sm="6" md="4">-->
                            <!--<v-autocomplete-->
                            <!--menu-props="auto"-->
                            <!--:label="$vuetify.lang.t('$vuetify.profileTZ')"-->
                            <!--v-model="profileInfo.timezone"-->
                            <!--:items="timezones"-->
                            <!--item-value="value"-->
                            <!--hide-details-->
                            <!--prepend-icon="fas fa-clock"-->
                            <!--single-line-->
                            <!--outlined-->
                            <!--:rules="requiredRules"-->

                            <!--&gt;-->

                            <!--</v-autocomplete>-->
                            <!--</v-col>-->

                            <!--</v-row>-->
                            <!--</v-form>-->
                            <!--</v-card-text>-->
                            <!--<v-card-actions>-->
                            <!--<v-spacer></v-spacer>-->
                            <!--<v-btn-->
                            <!--color="primary"-->
                            <!--@click="saveProfile"-->
                            <!--&gt;-->
                            <!--ادامه-->
                            <!--</v-btn>-->
                            <!--</v-card-actions>-->
                            <!--</v-card>-->


                            <!--</v-stepper-content>-->

                            <v-stepper-content step="1">
                                <v-card
                                        class="elevation-0"
                                >
                                    <v-card-text>
                                        <ClientPricingComponent></ClientPricingComponent>
                                    </v-card-text>
                                    <v-card-actions>
                                        <!--<v-btn class="elevation-0" @click="step=1">-->
                                        <!--<v-icon class="mx-1 grey&#45;&#45;text text&#45;&#45;darken-2" small>fas-->
                                        <!--fa-long-arrow-alt-right-->
                                        <!--</v-icon>-->
                                        <!--بازگشت-->
                                        <!--</v-btn>-->
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                color="primary"
                                                @click="setStep"
                                                :disabled="profile.wallet.total==0"
                                        >
                                            ادامه
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>

                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-card
                                        class="elevation-0"

                                >
                                    <v-card-text>


                                        <AddNewCycleDashboard @cycleAdded="postCycleAdd"
                                                              :dialog="false"></AddNewCycleDashboard>

                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn class="elevation-0" @click="step=1">
                                            <v-icon class="mx-1 grey--text text--darken-2" small>fas
                                                fa-long-arrow-alt-right
                                            </v-icon>
                                            بازگشت
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                color="primary"
                                                @click="addCycle"
                                        >
                                            ادامه
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                                <!--<v-card class="elevation-0">-->

                                <!--<v-card-text align="center">-->
                                <!--&lt;!&ndash;<v-col cols="12" lg="4" md="6" sm="8" class="text-center">&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-form&ndash;&gt;-->
                                <!--&lt;!&ndash;ref="sessionCountForm"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-text-field&ndash;&gt;-->

                                <!--&lt;!&ndash;outlined&ndash;&gt;-->
                                <!--&lt;!&ndash;label="تعداد جلسات"&ndash;&gt;-->
                                <!--&lt;!&ndash;placeholder="تعداد جلسات را وارد کنید"&ndash;&gt;-->
                                <!--&lt;!&ndash;v-model="sessionCount"&ndash;&gt;-->
                                <!--&lt;!&ndash;:rules="sessionCountRules"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;</v-text-field>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-form>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-col>&ndash;&gt;-->
                                <!--</v-card-text>-->
                                <!--&lt;!&ndash;<v-card-actions>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-btn class="elevation-0" @click="step = 2">&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-icon class="mx-1 grey&#45;&#45;text text&#45;&#45;darken-2" small>fas&ndash;&gt;-->
                                <!--&lt;!&ndash;fa-long-arrow-alt-right&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-icon>&ndash;&gt;-->
                                <!--&lt;!&ndash;بازگشت&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-btn>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-spacer></v-spacer>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-btn&ndash;&gt;-->
                                <!--&lt;!&ndash;color="primary"&ndash;&gt;-->
                                <!--&lt;!&ndash;@click="requestSession"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                                <!--&lt;!&ndash;ادامه&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-btn>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-card-actions>&ndash;&gt;-->
                                <!--</v-card>-->


                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <v-card>
                                    <v-card-text>
                                        <v-alert type="info">
                                            لطفا مشاور خود را انتخاب نمایید
                                        </v-alert>
                                        <v-row class="text-center" justify="center">

                                            <!--<v-col cols="3"-->
                                            <!--v-if="canRequestNewDoctors"-->
                                            <!--class="text-center">-->
                                            <!--<v-card-->
                                            <!--class="text-center "-->
                                            <!--@click="selectNewDoctor"-->
                                            <!--&gt;-->
                                            <!--<v-card-text>-->
                                            <!--<v-overlay-->
                                            <!--v-if="newDoctor != false"-->
                                            <!--absolute-->
                                            <!--z-index="0"-->
                                            <!--opacity=".6"-->

                                            <!--color="rgba(0,250,150,.4)"-->
                                            <!--&gt;-->

                                            <!--</v-overlay>-->

                                            <!--<template-->
                                            <!--v-if="newDoctor != false"-->
                                            <!--&gt;-->
                                            <!--<v-btn-->
                                            <!--fab-->
                                            <!--small-->
                                            <!--v-if="newDoctor"-->
                                            <!--class="green cursor-pointer green-check elevation-0"-->
                                            <!--&gt;-->
                                            <!--<v-icon class="white&#45;&#45;text" small>-->
                                            <!--fas fa-check-->
                                            <!--</v-icon>-->
                                            <!--</v-btn>-->
                                            <!--</template>-->

                                            <!--<v-avatar-->
                                            <!--:size="100"-->
                                            <!--class="elevation-2"-->
                                            <!--&gt;-->


                                            <!--<v-img-->
                                            <!--v-if="platform.logo != null"-->
                                            <!--:src="platform.logo"-->
                                            <!--&gt;</v-img>-->
                                            <!--</v-avatar>-->
                                            <!--</v-card-text>-->

                                            <!--<v-card-text class="text-center title pa-0 ma-0">-->
                                            <!--<span>درخواست دکتر جدید</span>-->
                                            <!--</v-card-text>-->
                                            <!--<v-card-text>-->

                                            <!--</v-card-text>-->

                                            <!--</v-card>-->

                                            <!--</v-col>-->

                                            <v-col v-for="doctor in doctors" :key="doctor.id" cols="3"
                                                   class="text-center">
                                                <v-card
                                                        class="text-center "
                                                        @click="selectDoctor(doctor)"
                                                >
                                                    <v-card-text>
                                                        <v-overlay
                                                                v-if="selectedDoctor != null"
                                                                absolute
                                                                z-index="0"
                                                                opacity=".6"
                                                                :value="selectedDoctor.id == doctor.id"
                                                                color="rgba(0,250,150,.4)"
                                                        >

                                                        </v-overlay>

                                                        <template
                                                                v-if="selectedDoctor != null"
                                                        >
                                                            <v-btn
                                                                    fab
                                                                    small
                                                                    v-if="selectedDoctor.id == doctor.id"
                                                                    class="green cursor-pointer green-check elevation-0"
                                                            >
                                                                <v-icon class="white--text" small>
                                                                    fas fa-check
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>


                                                        <v-avatar
                                                                :size="100"
                                                                class="elevation-2"
                                                        >

                                                            <v-img
                                                                    v-if="doctor.avatar != null"
                                                                    :src="doctor.avatar"
                                                            ></v-img>
                                                        </v-avatar>
                                                    </v-card-text>

                                                    <v-card-text class="text-center title pa-0 ma-0">
                                                        <span>{{doctor.full_name}}</span>
                                                    </v-card-text>
                                                    <v-card-text>

                                                        {{doctor.profession}}

                                                    </v-card-text>
                                                    <v-card-subtitle v-if="doctor.hasOwnProperty('total')">
                                                        تعداد بسته های شما با مشاور: {{doctor.total}}
                                                    </v-card-subtitle>
                                                </v-card>

                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col class="text-center">
                                                <v-btn v-if="canRequestNewDoctors" class="elevation-1"
                                                       @click="selectNewDoctorPass">انتخاب
                                                    مشاور جدید
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn @click="step--" text>قبلی</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                color="primary"
                                                @click="sendSessionRequest"
                                        >
                                            ثبت
                                        </v-btn>

                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <!--<v-stepper-content step="4">-->
                            <!--<v-card>-->
                            <!--<v-card-text>-->
                            <!--<v-alert type="info">-->
                            <!--لطفا از بین زمانهای مشاور، زمان مورد نظر خود را انتخاب نمایید.-->
                            <!--</v-alert>-->
                            <!--<template v-if="Object.keys(doctorTimes).length">-->
                            <!--<DoctorTimes @timeSelected="selectTime"-->
                            <!--:maxSelectSessionTime="maxSelectSessionTime"-->
                            <!--:doctorTimes="doctorTimes"></DoctorTimes>-->
                            <!--</template>-->
                            <!--</v-card-text>-->
                            <!--<v-card-actions>-->
                            <!--&lt;!&ndash;<v-btn @click="step=3" text>قبلی</v-btn>&ndash;&gt;-->

                            <!--<v-spacer></v-spacer>-->

                            <!--<v-btn-->
                            <!--color="primary"-->
                            <!--@click="saveTimeForRequest"-->
                            <!--&gt;-->
                            <!--ثبت-->
                            <!--</v-btn>-->

                            <!--</v-card-actions>-->
                            <!--</v-card>-->
                            <!--</v-stepper-content>-->

                            <v-stepper-content step="4">
                                <DateTimeComponent @done="redirect" :profile="profile"
                                                   @getProfile="updateProfile"></DateTimeComponent>
                                <!--<v-dialog v-model="showAddDialog" max-width="900" scrollable>-->
                                <!--<v-card>-->
                                <!--<v-card-title>ثبت زمان پیشنهادی</v-card-title>-->
                                <!--<v-divider></v-divider>-->

                                <!--<v-card-text>-->
                                <!--<v-row justify="center" align="center">-->
                                <!--<v-col cols="12" sm="12" md="7">-->
                                <!--<div class="text-center">-->
                                <!--<v-chip-->
                                <!--:small="$vuetify.breakpoint.smAndDown"-->
                                <!--class="mb-2 smooth-transition"-->
                                <!--style="display: inline-block"-->
                                <!--@click="isJalali = false"-->
                                <!--&gt;-->
                                <!--تقویم میلادی-->
                                <!--</v-chip>-->
                                <!--<div style="display: inline-block">-->
                                <!--<v-switch-->
                                <!--:dense="$vuetify.breakpoint.smAndDown"-->

                                <!--class="ma-3 mx-1 "-->
                                <!--v-model="isJalali"-->
                                <!--hide-details-->
                                <!--color="primary"-->
                                <!--&gt;-->
                                <!--</v-switch>-->
                                <!--</div>-->
                                <!--<v-chip-->
                                <!--:small="$vuetify.breakpoint.smAndDown"-->
                                <!--@click="isJalali = true"-->
                                <!--class="mb-2 smooth-transition"-->
                                <!--style="display: inline-block"-->
                                <!--&gt;-->
                                <!--تقویم شمسی-->
                                <!--</v-chip>-->
                                <!--</div>-->

                                <!--<AdvancedDatePicker v-if="isJalali" v-model="date"-->
                                <!--:eventArray="gregoryEvents"-->
                                <!--:min="min"-->
                                <!--:styles="'font-family: IranSans;padding:0 5px;'"-->
                                <!--&gt;-->
                                <!--<template v-slot:prev-icon>-->
                                <!--<v-btn fab x-small class="elevation-0">-->
                                <!--<v-icon fab small class="grey&#45;&#45;text">-->
                                <!--mdi-chevron-right-->
                                <!--</v-icon>-->
                                <!--</v-btn>-->
                                <!--</template>-->

                                <!--<template v-slot:next-icon>-->
                                <!--<v-btn fab x-small class="elevation-0">-->
                                <!--<v-icon fab class="grey&#45;&#45;text">-->
                                <!--mdi-chevron-left-->
                                <!--</v-icon>-->
                                <!--</v-btn>-->
                                <!--</template>-->
                                <!--<template v-slot:small-modal>-->


                                <!--</template>-->
                                <!--</AdvancedDatePicker>-->

                                <!--<v-date-picker v-else v-model="date"-->
                                <!--:min="minGregory"-->
                                <!--first-day-of-week="6"-->
                                <!--class="elevation-0"-->
                                <!--:events="gregoryEvents"-->
                                <!--:width="'100%'"-->
                                <!--&gt;</v-date-picker>-->
                                <!--</v-col>-->
                                <!--<v-col cols="12" sm="12" md="5">-->
                                <!--<v-form ref="addToTimesForm">-->

                                <!--&lt;!&ndash;<template v-if="cart!=null && cart.currency!='dollar'">&ndash;&gt;-->
                                <!--&lt;!&ndash;<date-picker&ndash;&gt;-->
                                <!--&lt;!&ndash;v-model="currentTime.date"&ndash;&gt;-->
                                <!--&lt;!&ndash;format="YYYY-MM-DD"&ndash;&gt;-->
                                <!--&lt;!&ndash;element="persian-date-picker"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;</date-picker>&ndash;&gt;-->

                                <!--&lt;!&ndash;<v-text-field id="persian-date-picker"&ndash;&gt;-->
                                <!--&lt;!&ndash;v-model="currentTime.date"&ndash;&gt;-->
                                <!--&lt;!&ndash;label="تاریخ مورد نظر"&ndash;&gt;-->
                                <!--&lt;!&ndash;prepend-icon="event"&ndash;&gt;-->
                                <!--&lt;!&ndash;readonly&ndash;&gt;-->
                                <!--&lt;!&ndash;outlined&ndash;&gt;-->
                                <!--&lt;!&ndash;:rules="requiredRules"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;</v-text-field>&ndash;&gt;-->
                                <!--&lt;!&ndash;</template>&ndash;&gt;-->
                                <!--&lt;!&ndash;<template v-else>&ndash;&gt;-->

                                <!--&lt;!&ndash;<v-menu&ndash;&gt;-->
                                <!--&lt;!&ndash;ref="menu"&ndash;&gt;-->
                                <!--&lt;!&ndash;v-model="menu"&ndash;&gt;-->
                                <!--&lt;!&ndash;:close-on-content-click="false"&ndash;&gt;-->
                                <!--&lt;!&ndash;:return-value.sync="currentTime.date"&ndash;&gt;-->
                                <!--&lt;!&ndash;transition="scale-transition"&ndash;&gt;-->
                                <!--&lt;!&ndash;offset-y&ndash;&gt;-->
                                <!--&lt;!&ndash;max-width="290px"&ndash;&gt;-->
                                <!--&lt;!&ndash;min-width="290px"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                                <!--&lt;!&ndash;<template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-text-field&ndash;&gt;-->
                                <!--&lt;!&ndash;v-model="currentTime.date"&ndash;&gt;-->
                                <!--&lt;!&ndash;label="تاریخ مورد نظر"&ndash;&gt;-->
                                <!--&lt;!&ndash;prepend-icon="event"&ndash;&gt;-->
                                <!--&lt;!&ndash;readonly&ndash;&gt;-->
                                <!--&lt;!&ndash;v-bind="attrs"&ndash;&gt;-->
                                <!--&lt;!&ndash;v-on="on"&ndash;&gt;-->
                                <!--&lt;!&ndash;outlined&ndash;&gt;-->
                                <!--&lt;!&ndash;:rules="requiredRules"&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;</v-text-field>&ndash;&gt;-->
                                <!--&lt;!&ndash;</template>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-date-picker&ndash;&gt;-->
                                <!--&lt;!&ndash;v-model="currentTime.date"&ndash;&gt;-->
                                <!--&lt;!&ndash;no-title&ndash;&gt;-->
                                <!--&lt;!&ndash;scrollable&ndash;&gt;-->
                                <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-spacer></v-spacer>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-btn text color="primary" @click="menu = false">Cancel&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-btn>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-btn text color="primary"&ndash;&gt;-->
                                <!--&lt;!&ndash;@click="$refs.menu.save(currentTime.date)">OK&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-btn>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-date-picker>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-menu>&ndash;&gt;-->
                                <!--&lt;!&ndash;</template>&ndash;&gt;-->
                                <!--<v-row>-->
                                <!--<v-col cols="3"-->
                                <!--sm="6"-->
                                <!--class="pa-1 pa-sm-1"-->
                                <!--v-for="(period,index) in periods"-->
                                <!--:key="index" @click="selectPeriod(period)">-->
                                <!--<v-hover v-slot:default="{ hover }">-->
                                <!--<v-card class="cursor-pointer smooth-color"-->
                                <!--:class="{'info':currentTime.periods.includes(period),'grey' : !currentTime.periods.includes(period)}"-->
                                <!--:elevation="hover ? 10 : 1"-->
                                <!--style="cursor: pointer;"-->
                                <!--&gt;-->
                                <!--&lt;!&ndash;<v-fade-transition>&ndash;&gt;-->
                                <!--&lt;!&ndash;<template v-if="currentTime != null">&ndash;&gt;-->

                                <!--&lt;!&ndash;<v-overlay&ndash;&gt;-->

                                <!--&lt;!&ndash;v-if="currentTime.periods.includes(period)"&ndash;&gt;-->
                                <!--&lt;!&ndash;absolute&ndash;&gt;-->
                                <!--&lt;!&ndash;color="#000">&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-chip class="green cursor-pointer">&ndash;&gt;-->
                                <!--&lt;!&ndash;<span>انتخاب شد</span>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-icon class="ma-1" small>fas&ndash;&gt;-->
                                <!--&lt;!&ndash;fa-check&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-icon>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-chip>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-overlay>&ndash;&gt;-->

                                <!--&lt;!&ndash;</template>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-fade-transition>&ndash;&gt;-->
                                <!--<v-row-->
                                <!--align="end"-->
                                <!--class="fill-height">-->
                                <!--<v-col-->
                                <!--align-self="start"-->
                                <!--class="pa-0"-->
                                <!--cols="12">-->
                                <!--</v-col>-->
                                <!--<v-col>-->
                                <!--<v-list-item-->
                                <!--color="rgba(0, 0, 0, .4)"-->
                                <!--class="py-0"-->
                                <!--dark-->
                                <!--&gt;-->
                                <!--<v-list-item-content class="py-0">-->
                                <!--<v-list-item-title-->
                                <!--class="black&#45;&#45;text">-->
                                <!--<span class="white&#45;&#45;text">{{period.title}} </span>-->
                                <!--</v-list-item-title>-->
                                <!--</v-list-item-content>-->
                                <!--</v-list-item>-->
                                <!--</v-col>-->
                                <!--</v-row>-->
                                <!--</v-card>-->
                                <!--</v-hover>-->
                                <!--</v-col>-->
                                <!--</v-row>-->

                                <!--</v-form>-->
                                <!--</v-col>-->
                                <!--</v-row>-->

                                <!--&lt;!&ndash;<v-list dense>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-list-item v-for="(time,index) in dates" :key="index" dense>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-chip-group column dense >&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-chip class="primary mx-1" small>{{time.date}}&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-chip>&ndash;&gt;-->
                                <!--&lt;!&ndash;<template v-for="(timePeriod,index) in time.periods">&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-chip :key="index" class="mx-1 info" close small&ndash;&gt;-->
                                <!--&lt;!&ndash;@click:close="removeTime(time,timePeriod)">&ndash;&gt;-->
                                <!--&lt;!&ndash;{{timePeriod.title}}&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-chip>&ndash;&gt;-->
                                <!--&lt;!&ndash;</template>&ndash;&gt;-->

                                <!--&lt;!&ndash;</v-chip-group>&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-btn fab text @click="removeTimeRecord(time)">&ndash;&gt;-->
                                <!--&lt;!&ndash;<v-icon class="red&#45;&#45;text mx-5" small>fas fa-trash&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-icon>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-btn>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-list-item>&ndash;&gt;-->
                                <!--&lt;!&ndash;</v-list>&ndash;&gt;-->
                                <!--<v-data-table-->
                                <!--mobile-breakpoint="400"-->
                                <!--:items="datesWithPeriods"-->
                                <!--:headers="headers"-->
                                <!--dense-->
                                <!--hide-default-footer-->
                                <!--&gt;-->
                                <!--<template v-slot:item="{item}">-->
                                <!--<tr class="pa-0 ma-0" @click="selectDayByTable(item)">-->
                                <!--<td>-->
                                <!--<template v-if="isJalali">-->
                                <!--{{item.date| toJalali}}-->
                                <!--</template>-->
                                <!--<template v-else>-->
                                <!--{{item.date}}-->
                                <!--</template>-->
                                <!--</td>-->
                                <!--<td>-->
                                <!--<template v-for="(timePeriod,index) in item.periods">-->
                                <!--<v-chip :key="index" class="ma-1 info" close small-->
                                <!--@click:close="removeTime(item,timePeriod)">-->
                                <!--{{timePeriod.title}}-->
                                <!--</v-chip>-->
                                <!--</template>-->
                                <!--</td>-->
                                <!--<td>-->
                                <!--<v-btn fab x-small text @click="removeTimeRecord(item)">-->
                                <!--<v-icon class="red&#45;&#45;text mx-5" small>fas fa-trash-->
                                <!--</v-icon>-->
                                <!--</v-btn>-->
                                <!--</td>-->
                                <!--</tr>-->
                                <!--</template>-->
                                <!--</v-data-table>-->
                                <!--</v-card-text>-->

                                <!--<v-card-text class="elevation-1">-->

                                <!--</v-card-text>-->

                                <!--<v-card-actions>-->
                                <!--<v-btn text class="danger" @click="showAddDialog = false">بستن</v-btn>-->
                                <!--<v-spacer></v-spacer>-->
                                <!--<v-btn text class="primary" @click="addToTimes">ثبت</v-btn>-->
                                <!--</v-card-actions>-->
                                <!--</v-card>-->
                                <!--</v-dialog>-->
                                <!--<v-card-->
                                <!--class="elevation-0"-->
                                <!--&gt;-->
                                <!--<v-card-text>-->
                                <!--<v-alert type="info" small dense>-->
                                <!--لطفا همه زمانهای مناسب برای برگزاری جلسه آینده را به زمان محلی خود را-->
                                <!--وارد نمایید، هرچه تعداد بیشتری زمان وارد کنید، امکان برگزاری سریع تر جلسه را-->
                                <!--به تیم پشتیبانی میدهید.-->
                                <!--</v-alert>-->
                                <!--</v-card-text>-->
                                <!--<v-card-text class="text-center">-->
                                <!--<v-btn class="info" @click="showAddDialog = true">-->
                                <!--افزودن زمان پیشنهادی-->
                                <!--<v-icon small class="mx-1 ">fas fa-plus</v-icon>-->
                                <!--</v-btn>-->
                                <!--</v-card-text>-->
                                <!--<v-card-text align="center">-->
                                <!--<v-col cols="12" md="8">-->
                                <!--<v-card>-->
                                <!--<v-card-title>-->
                                <!--زمانهای ثبت شده-->
                                <!--</v-card-title>-->
                                <!--<v-divider></v-divider>-->
                                <!--<v-card-text>-->
                                <!--<v-list>-->
                                <!--<v-list-item v-for="(time,index) in selectedTimes" :key="index">-->
                                <!--<v-chip-group-->
                                <!--column-->

                                <!--&gt;-->
                                <!--<v-chip class="primary mx-1" small>{{time.date}}-->
                                <!--</v-chip>-->
                                <!--<template v-for="(timePeriod,index) in time.periods"-->
                                <!--&gt;-->
                                <!--<v-chip :key="index" class="mx-1 info" close small-->
                                <!--@click:close="removeTime(time,timePeriod)">-->
                                <!--{{timePeriod.title}}-->
                                <!--</v-chip>-->
                                <!--</template>-->

                                <!--</v-chip-group>-->
                                <!--<v-spacer></v-spacer>-->
                                <!--<v-btn fab text @click="removeTimeRecord(time)">-->
                                <!--<v-icon class="red&#45;&#45;text mx-5" small>fas fa-trash-->
                                <!--</v-icon>-->
                                <!--</v-btn>-->
                                <!--</v-list-item>-->
                                <!--</v-list>-->
                                <!--</v-card-text>-->
                                <!--</v-card>-->
                                <!--</v-col>-->

                                <!--</v-card-text>-->
                                <!--<v-card-actions>-->
                                <!--<v-btn class="elevation-0" @click="step = 2">-->
                                <!--<v-icon class="mx-1 grey&#45;&#45;text text&#45;&#45;darken-2" small>fas-->
                                <!--fa-long-arrow-alt-right-->
                                <!--</v-icon>-->
                                <!--بازگشت-->
                                <!--</v-btn>-->
                                <!--<v-spacer></v-spacer>-->
                                <!--<v-btn-->
                                <!--color="primary"-->
                                <!--@click="sendTimes"-->
                                <!--&gt;-->
                                <!--ثبت-->
                                <!--</v-btn>-->
                                <!--</v-card-actions>-->
                                <!--</v-card>-->
                            </v-stepper-content>

                            <!--<v-stepper-content step="6">-->
                            <!--<v-card class="elevation-0"-->
                            <!--v-if="selectedCycle!=null"-->
                            <!--&gt;-->
                            <!--<v-card-title>-->
                            <!--درخواست جلسه برای دوره-->
                            <!--<span v-if="cycles.length>1">-->
                            <!--<v-select-->
                            <!--:items="cycles"-->
                            <!--v-model="selectedCycle"-->
                            <!--label="دوره"-->
                            <!--return-object-->
                            <!--item-text="heading"-->
                            <!--item-value="id"-->
                            <!--outlined-->
                            <!--dense-->
                            <!--class="mt-7 mx-3"-->
                            <!--&gt;</v-select>-->
                            <!--</span>-->
                            <!--<span v-else-if="selectedCycle!=null" class="mx-1 font-weight-bold">-->
                            <!--{{selectedCycle.heading}}-->
                            <!--</span>-->
                            <!--<v-spacer></v-spacer>-->

                            <!--<v-btn v-if="cycles.length >1" class="elevation-0" color="primary" small-->
                            <!--@click="step=1">-->
                            <!--<v-icon>mdi-chevron-right</v-icon>-->
                            <!--بازگشت-->
                            <!--</v-btn>-->

                            <!--</v-card-title>-->
                            <!--<v-divider></v-divider>-->
                            <!--<v-card-title>-->
                            <!--<div>شرح موضوع:</div>-->
                            <!--</v-card-title>-->
                            <!--<v-card-text class="mx-5">-->
                            <!--{{selectedCycle.problem_description}}-->
                            <!--</v-card-text>-->

                            <!--<v-card-text align="center">-->
                            <!--<v-col cols="12" lg="4" md="6" sm="8" class="text-center">-->
                            <!--<v-form-->
                            <!--ref="sessionCountForm"-->
                            <!--&gt;-->
                            <!--<v-text-field-->

                            <!--outlined-->
                            <!--label="تعداد جلسات"-->
                            <!--placeholder="تعداد جلسات را وارد کنید"-->
                            <!--v-model="sessionCount"-->
                            <!--:rules="sessionCountRules"-->
                            <!--&gt;</v-text-field>-->
                            <!--</v-form>-->
                            <!--</v-col>-->
                            <!--</v-card-text>-->
                            <!--<v-card-actions>-->
                            <!--<v-btn class="elevation-0" @click="step = 3">-->
                            <!--<v-icon class="mx-1 grey&#45;&#45;text text&#45;&#45;darken-2" small >fas-->
                            <!--fa-long-arrow-alt-right-->
                            <!--</v-icon>-->
                            <!--بازگشت-->
                            <!--</v-btn>-->
                            <!--<v-spacer></v-spacer>-->
                            <!--<v-btn-->
                            <!--color="primary"-->
                            <!--@click="requestSession"-->
                            <!--&gt;-->
                            <!--ادامه-->
                            <!--</v-btn>-->
                            <!--</v-card-actions>-->
                            <!--</v-card>-->


                            <!--</v-stepper-content>-->

                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-row>
            <v-col>
                <!--<user-state></user-state>-->
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    /*eslint-disable*/
    // import UserState from '@/components/UserState.vue'

    // import AddNewCycle from '@/components/clients/AddNewCycle';
    import AddNewCycleDashboard from '@/components/clients/AddNewCycleDashboard';
    import ClientPricingComponent from "../products/ClientPricingComponent";
    // import Cycles from "../../views/client/Cycles";
    import {mapGetters} from 'vuex';
    import timezones from "../../data/timezones";
    import moment from 'moment-timezone';
    import momentJalali from 'moment-jalaali';
    import {EventBus} from "@/event-bus";
    import DoctorTimes from '@/components/clients/DoctorTimes';
    import AdvancedDatePicker from '@/components/datepicker/AdvancedDatePicker'
    import DateTimeComponent from '@/components/DateTimeComponent';

    export default {
        created() {
            this.$store.dispatch('checkLogin').then(() => {
                this.$store.dispatch('getCart').then(() => {

                    this.$store.dispatch('getUserInfo').then(() => {
                        this.setStep();
                        this.fillFirstStep();
                        this.fillThirdStep();
                        this.loaded = true;
                    });

                    this.$store.dispatch('getCountries').then((resp) => {
                        this.countries = resp.data.countries;
                    });

                });
            });
            this.sortAndGenerateTimezoneOffset();


        },
        mounted() {
            EventBus.$on('notify', this.showSnackbar);
        },
        methods: {
            updateProfile() {
                this.$store.dispatch('getUserInfo').then(() => {
                    this.profile = this.$store.getters.profile;
                })
            },
            redirect() {
                this.$router.push({name: 'sessions', params: {lang: 'fa'}});
            },
            selectNewDoctorPass() {
                this.selectedDoctor = null;
                this.newDoctor = true;
                this.sendSessionRequest();
            },
            selectTime(hours) {
                this.selectedSessionTimes = hours;
            },
            saveTimeForRequest() {

                if (this.selectedDoctor != null) {

                    if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length) {

                        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.ExperssComponent.selectOne'));

                    }
                    else if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length == 0) {

                        this.step = 5;

                    } else {

                        let payload = {
                            times: this.selectedSessionTimes,
                            sessions: this.sessions
                        };

                        this.$store.dispatch('selectMultipleSessionRequestTime', payload).then((resp) => {
                            EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfully'));
                            this.step = 5;
                        });
                    }

                } else {
                    this.$router.push('sessions');
                }
            },
            showSnackbar(color = 'success', timeout = 5000, message = this.$t('$vuetify.Notify.successfully')) {
                this.snackbar.visibility = true;
                this.snackbar.color = color;
                this.snackbar.position = 'bottom';
                this.snackbar.timeout = timeout;
                this.snackbar.mode = 'vertical';
                this.snackbar.message = message;

            },
            fillThirdStep() {
                this.cycles = this.profile.cycles;

                if (this.profile.wallet.global_packages > this.profile.wallet.dr_packages) {
                    this.sessionCount = this.profile.wallet.global_packages;
                } else {
                    this.sessionCount = this.profile.wallet.dr_packages;
                }

                if (this.cycles.length >= 1) {
                    this.selectedCycle = this.cycles[0];
                }
            },
            fillFirstStep() {
                this.profileInfo.name = this.profile.firstname;
                this.profileInfo.family = this.profile.lastname;
                if (!this.profile.requiredFields.includes('timezone')) {
                    this.profileInfo.timezone = this.profile.tz;
                }

                this.profileInfo.country = this.profile.country_id;
            },
            sortAndGenerateTimezoneOffset() {
                let x = JSON.parse(JSON.stringify(timezones));
                let tempTime = x.map((item) => {
                    let tznum = moment.tz(item.text).format('Z');

                    item.offset = moment.tz(item.text).utcOffset();
                    item.text = item.text + ' ' + tznum;
                    return item;
                });
                tempTime = tempTime.sort((a, b) => {
                    return a.offset - b.offset;
                });
                this.timezones = tempTime;
            },
            postCycleAdd(data) {

                this.sessionCount = data.sessionCount;
                this.cycles = this.profile.cycles;
                this.selectedCycle = this.profile.cycles[0];

                if (this.doctors.length == 0 && this.canRequestNewDoctors) {

                    this.selectedDoctor = null;
                    this.newDoctor = true;

                    let options = {
                        "cycle_id": this.selectedCycle.id,
                        "session_count": this.sessionCount
                    };

                    if (this.selectedDoctor != null) {
                        options.doctor_id = this.selectedDoctor.id;
                    }

                    this.$store.dispatch('sendSessionRequest', options).then((resp) => {
                        this.$nextTick(() => {
                            this.step = 4;
                        });
                        EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfullyRequest'));
                    });

                } else {
                    this.step++;
                }

                // this.setStep();
            },
            addCycle() {
                console.log('asd');
                EventBus.$emit('postConsultationInfoEvent');
                // this.setStep();
            },
            sendSessionRequest() {
                if (!this.newDoctor && this.selectedDoctor == null) {
                    EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.ExperssComponent.selectOne'));
                    return;
                }

                let options = {
                    "cycle_id": this.selectedCycle.id,
                    "session_count": this.sessionCount
                };
                if (this.selectedDoctor != null) {
                    options.doctor_id = this.selectedDoctor.id;

                } else {
                    this.completed = true;
                }

                this.$store.dispatch('sendSessionRequest', options).then((resp) => {

                    if (resp.data.hasOwnProperty('doctorTimes')) {
                        this.doctorTimes = resp.data.doctorTimes;
                    }

                    if (resp.data.hasOwnProperty('sessions')) {
                        this.maxSelectSessionTime = resp.data.sessions.length;
                        this.sessions = resp.data.sessions;
                    }
                    EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfullyRequest'));
                    this.step = 4;

                    // if (Object.keys(this.doctorTimes).length) {
                    //     this.step = 4;
                    // } else {
                    //     this.step = 5;
                    // }


                    // this.$store.dispatch('getUserInfo');
                    // if (this.selectedDoctor == null) {
                    //     this.completed = true;
                    // }
                    //
                    // if (resp.data.hasOwnProperty('doctorTimes')) {
                    //     this.doctorTimes = resp.data.doctorTimes;
                    // }
                    //
                    // if (resp.data.hasOwnProperty('sessions')) {
                    //     this.maxSelectSessionTime = resp.data.sessions.length;
                    //     this.sessions = resp.data.sessions;
                    // }
                    //
                    // this.$nextTick().then(() => {
                    //     this.e1 = 3;
                    // });
                });
                // console.log("----------------------finalStep----------------------");
                // console.log('finalStep');
                // console.log("+-+-+-+-+-+-+-+-+-+-+-+-finalStep+-+-+-+-+-+-+-+-+-+-+-");
            },
            selectDoctor(doctor) {
                this.newDoctor = false;
                if (this.selectedDoctor == doctor) {
                    this.selectedDoctor = null;
                } else {
                    this.selectedDoctor = doctor;
                }
            },
            selectNewDoctor() {
                this.selectedDoctor = null;

                this.newDoctor = !this.newDoctor;
            },
            getDoctors(cycle) {
                return new Promise((resolve) => {
                    this.$store.dispatch('getSessionRequestDoctors', {cycleId: cycle.id}).then((resp) => {
                        this.doctors = resp.data.results.doctors;

                        this.selectedDoctor = null;
                        this.newDoctor = false;

                        if (this.doctors.length == 1) {
                            this.selectedDoctor = this.doctors[0];
                        }

                        if (resp.data.selected_doctor != null) {
                            this.selectedDoctor = this.doctors.find(doctor => doctor.id == resp.data.selected_doctor.id);
                        }


                        this.canRequestNewDoctors = resp.data.can_request_new_doctor;
                        resolve();
                    })
                })

            },


            sendTimes() {

                let data = {timebooks: []};

                this.selectedTimes.forEach(day => {
                    let start = "";
                    let end = "";

                    data.timebooks.push({'date': day.date.replace(/-/g, '/'), 'periods': []});

                    day.periods.forEach(time => {
                        start = time.from;
                        end = time.to;
                        data.timebooks.forEach(item => {
                            item.periods.push({'start': start + ':00', 'end': end + ':00'})
                        })
                    })
                });
                this.$store.dispatch('clientPostConsultInfo', data).then(() => {
                    EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.ExperssComponent.successSelect'));
                    this.$router.push({name: 'sessions', params: {lang: 'fa'}});
                })
            },

            unique(arr) {
                let a = arr.concat();
                for (let i = 0; i < a.length; ++i) {
                    for (let j = i + 1; j < a.length; ++j) {
                        if (a[i] === a[j])
                            a.splice(j--, 1);
                    }
                }

                return a;
            },
            removeTime(time, timePeriod) {
                time.periods.splice(time.periods.indexOf(timePeriod), 1);
                if (!time.periods.length) {
                    this.removeTimeRecord(time);
                }
            },
            removeTimeRecord(time) {
                this.selectedTimes.splice(this.selectedTimes.indexOf(time), 1);
            },
            //
            // addToTimes() {
            //     if (this.$refs.addToTimesForm.validate()) {
            //
            //         if (!this.currentTime.periods.length) {
            //             EventBus.$emit('notify', 'red', undefined, 'لطفا حداقل یک بازه را انتخاب نمایید');
            //         } else {
            //             let x = {
            //                 date: this.currentTime.date,
            //                 periods: this.currentTime.periods
            //             };
            //             let exists = this.selectedTimes.find((time) => {
            //                 return time.date == x.date;
            //             });
            //             console.log(exists);
            //             if (exists != undefined) {
            //                 // this.removeTime(exists);
            //                 // this.exists.periods
            //
            //                 exists.periods = this.unique(exists.periods.concat(x.periods));
            //                 // console.log(exists.periods.filter(value => x.periods.includes(value)));
            //                 // this.removeTimeRecord(exists);
            //                 // this.selectedTimes.push(toAdd);
            //             } else {
            //
            //                 this.selectedTimes.push(x);
            //             }
            //             this.currentTime.date = '';
            //             this.currentTime.periods = [];
            //             this.showAddDialog = false;
            //
            //         }
            //     }
            // },
            selectPeriod(period) {

                if (!this.currentTime.periods.includes(period)) {
                    this.currentTime.periods.push(period);
                } else {
                    this.currentTime.periods.splice(this.currentTime.periods.indexOf(period), 1);
                }
            },
            saveProfile() {
                let data = {
                    name: this.profileInfo.name,
                    family: this.profileInfo.family,
                    timezone: this.profileInfo.timezone,
                    country_id: this.profileInfo.country,
                };

                if (this.$refs.profileForm.validate()) {
                    this.$store.dispatch('saveUserInfo', data).then(() => {
                        this.$store.dispatch('getUserInfo').then(() => {
                            EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfully'));
                            EventBus.$emit('clientProfileSaved');
                            this.setStep();
                        });
                    }).catch(() => {
                        EventBus.$emit('clientProfileSaveError');
                    }).finally(() => {
                        EventBus.$emit('clientProfileSaveError');
                    });
                }
                this.setStep();
            },
            setStep() {


                if (this.profile.state.requireBuy || this.profile.wallet.total == 0) {
                    this.step = 1;
                } else {
                    this.step = 2;
                }
                // else if (this.profile.state.requireFillTimebooks) {
                //     this.step = 4;
                // } else {
                //     this.wizard = false;
                // }
            }
        },
        components: {
            // AddNewCycle,
            DateTimeComponent,
            AdvancedDatePicker,
            DoctorTimes,
            AddNewCycleDashboard,
            ClientPricingComponent
        },
        data() {
            return {
                isJalali: false,
                date: null,
                maxSelectSessionTime: 0,
                selectedSessionTimes: [],
                doctorTimes: {},
                snackbar: {
                    visibility: false,
                    color: 'info',
                    position: 'bottom',
                    timeout: 5000,
                    mode: 'vertical',
                    message: 'this is snackbar message',
                },
                canRequestNewDoctors: false,
                doctors: [],
                selectedDoctor: null,
                newDoctor: false,

                sessionCount: 1,
                cycles: [],
                selectedCycle: null,
                currentTime: {
                    date: '',
                    periods: []
                },
                selectedTimes: [],

                periods: [
                    {title: 'صبح', from: '8', to: '12'},
                    {title: 'ظهر', from: '12', to: '16'},
                    {title: 'عصر', from: '16', to: '20'},
                    {title: 'شب', from: '20', to: '24'},
                ],
                menu: false,
                showAddDialog: false,
                loaded: false,
                timezones: timezones,
                profileInfo: {
                    name: '',
                    family: '',
                    country: '',
                    timezone: '',
                },
                countries: [],
                country: null,
                step: 0,
                wizard: true
            }
        },
        computed: {
            sessionRequestTitle() {
                if (this.profile.cycles.length) {
                    return 'درخواست جلسه';
                } else {
                    return 'موضوع مشاوره';
                }

            },
            sessionCountRules() {
                return [
                    value => !!value || 'تعداد جلسات را وارد نمایید.',
                    value => (value > 0) || 'تعداد جلسات حداقل باید 1 باشد',
                    value => (value <= this.profile.wallet.total) || 'با درخواستی باید از جلسات خریداری شده شما کمتر باشد',
                ];
            },
            needsCycle() {
                return this.profile.state.requireCycle
            },
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            ...mapGetters({
                profile: 'getUserInfo',
                platform: 'getPlatform',
                cart: 'getCart'
            }),
        },
        watch: {
            selectedCycle() {
                if (!this.profile.state.requireSign && !this.profile.state.requireBuy && this.profile.wallet.total) {
                    this.getDoctors(this.selectedCycle);
                }
            },
            wizard() {
                this.$router.push({name: 'sessions', params: {lang: 'fa'}});
            },
            isJalali(newVal, oldVal) {
                if (newVal) {
                    if (this.date != null) {
                        this.date = momentJalali(this.date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
                    }
                } else {
                    if (this.date != null) {
                        this.date = momentJalali(this.date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD')
                    }
                }
            }
        }

    }
</script>

<style scoped>
    .green-check {
        position: absolute !important;
        top: -10px !important;
        right: -10px !important;
    }
</style>